#treeview {
    display: inline-block;
    vertical-align: top;
}

.dx-list-item-content {
    padding-left: 0;
}

li.dx-state-focused .dx-treeview-item {
    background-color: whitesmoke;
    border-radius: 3px;
}

.selected {
    background-color: lightblue;
}

.mimg {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.custom-hidden-button {
    border-radius: 12px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    margin-left: -4px;
    cursor: pointer;

    &:hover, &:active {
        background-color: rgba($color: #868686, $alpha: 0.1);
        border: 1px solid rgba($color: #868686, $alpha: 0.2);
    }
}
