.dashboard-data-grid-headers {
    color: #969696;
    width: 100%;
    height : "10px"
}

.dashboard-data-grid-headers:focus-within {
    outline: none !important;
}

.dashboard-data-grid {
    /* border-top: 0px !important; */
    border-radius: 10px !important;
    padding-top: 8px;
}

.evenRow {
    background-color: #f4f4f4;
}

.file-name {
    margin-top: -3px !important;
    color: #747474
}

.version-on-kmat-field {
    margin-left: 10px;
}

.dot {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.imports-count {
    height: 18px;
    width: 18px;
    background-color: #585858;
    color: white;
    border-radius: 50%;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.product-line-count {
    height: 25px;
    width: 25px;
    background-color: #f1f1f1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.imports-count:hover {
    user-select: none;
}

.row-status {
    display: flex;
    align-items: center;
}

.header-children {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.last-column {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    overflow: visible !important;
}

.effective-date-data {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.select-field {
    border-radius: 10px !important;
}

.product-details-title {
    padding-bottom: 2px !important;
    padding-top: 8px !important;
    font-size: 22px !important;
}

.assign-editor-title {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    font-size: 22px !important;
}

.released-status {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-size: 22px !important;
}

.item-type-label-header {
    margin-top: -3px !important;
}

.clear-filter {
    margin-right: 7px;
}

.clear-filter:hover {
    background-color: #e3e9e9;
    border-radius: 15px;
}

.product-lines-and-counts {
    display: flex !important;
    justify-content: space-between !important;
}

.update-model-btn {
    margin-left: 5px !important;
}


.product-line-name-and-count {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.product-line-count-chip {
    margin-right: 15px;
}

.autocomplete-users-list {
    display: flex;
}

.autocomplete-avatar {
    margin-right: 15px;
}

.effective-date-in-imports {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
}

.custom-dialog-paper {
    border-radius: 15px;
    margin: 1px;
    padding: 1px;
    width: 100%;
    max-width: 600px;
}

.product-select {
    margin-bottom: 10px !important;
    padding: 5px;
    border-radius: 12px !important;
}

.radio-group {
    margin-left: 5px;
}

.sub-header {
    margin: 10px 0px 5px 5px;
}

.popOver {
    & .MuiPopover-paper {
        padding: 15px 10px;
        display: flex;
        gap: 0;
        flex-direction: column;
        text-align: left;
        margin-top: 5px;
    }
}

.btnStyles {
    text-align: left;
    justify-content: left;
    display: flex;
    width: 100%;
}

.clickable {
    cursor: pointer;
}
