@font-face {
  font-family: 'HPSimplified_Lt';
  font-weight: 600;
  font-style: normal;
  src: local('HPSimplified_Lt'),
      url(assets/fonts/HPSimplified_Lt.ttf) format('truetype');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'HPSimplified_LtIt';
  font-weight: 600;
  font-style: italic;
  src: local('HPSimplified_LtIt'),
      url(assets/fonts/HPSimplified_LtIt.ttf) format('truetype');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'HPSimplified_Rg';
  font-weight: 900;
  font-style: normal;
  src: local('HPSimplified_Rg'),
      url(assets/fonts/HPSimplified_Rg.ttf) format('truetype');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'HPSimplified_It';
  font-weight: 900;
  font-style: italic;
  src: local('HPSimplified_It'),
      url(assets/fonts/HPSimplified_It.ttf) format('truetype');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
