.info {
    position: relative;
    display: 'flex';
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: -20px 0px;
    text-align: center;
    top: 50%;
    flex: 1;
}

.info .blinking {
    animation: blinkingText 1s infinite;
}

.info.error {
    color: red;
}

.info.message {
    color: black;
}

@keyframes blinkingText {
    0% {
        color: red;
    }
    49% {
        color: red;
    }
    60% {
        color: transparent;
    }
    99% {
        color: transparent;
    }
    100% {
        color: red;
    }
}
