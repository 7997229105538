body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
select,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}

a,
object,
embed {
    outline: 0 none;
}

img {
    border: 0;
}

fieldset {
    border: 0 none;
    margin: 0px;
    padding: 0px;
}

.pcr-user {
    white-space: nowrap;
    margin-right: 130px;
    margin-left: -140px;
    float: right;
}

.pcr-header-user {
    text-decoration: none;
    font-size: 16px;
    margin-top: 6px;
    color: #5a5a5a !important;
    z-index: 0;
    display: flex;
    position: absolute;
    right: 12px;
    top: -3px;
}

.pcr-header-username {
    padding-bottom: 3px;
}

.pcr-header-profile-info {
    padding-left: 5px;
}

.pcr-header-exit {
    padding-right: 10px;
    padding-top: 40px;
    vertical-align: bottom;
}

ul {
    list-style-type: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
body,
#root,
.pcr-container {
    font-family: Forma DJR Micro !important;
    margin: 0;
    height: 100%;
    box-sizing: border-box;
}

* {
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: rgb(249, 249, 249);
    color: #000;
    font-size: 16px;
}

div.body {
    margin: 0 auto;
    padding-bottom: 0px;
    position: relative;
}

button {
    font-size: 1.2rem;
}

.full-width {
    width: 100%;
}

select {
    border: 1px solid #ccc;
    padding: 0px 2px;
    outline: none;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    font-family: Forma DJR Micro !important;
    font-size: 14px;
    color: #777;
    margin: 0;
    background: #fff;
    cursor: pointer;
}

select:focus,
select:hover {
    border: 2px solid #aaaaaa;
}

.pcr-container {
    display: grid;
    grid-template-areas:
        'header'
        'main'
        'footer';
    grid-template-columns: 1fr;
    grid-template-rows: 45px 1fr 20px;
    gap: 0.1rem;
}

.pcr-header {
    grid-area: header;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0px 0px;
    line-height: 1.1em;
    background-color: #B2C1D4;
    color: black;
}

.pcr-header-logo-img {
    padding-top: 3px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.pcr-header-logo-text {
    margin: 3px 3px;
    width: max-content;
    text-align: center;
    white-space: nowrap;
}

.pcr-header-logout {
    color: #007dba;
    text-decoration: none;
    display: inline;
    float: right;
    margin-left: 20px;
}

.pcr-header-logout:hover {
    color: #01a7fa;
    cursor: pointer;
}

.header-links a {
    color: #ffffff;
    text-decoration: none;
    padding: 1rem;
}

.header-links a:hover {
    color: #ff8000;
}

.pcr-main {
    grid-area: main;
    width: 100%;
    max-width: calc(100% - 185px);
    margin-left: 155px;
    padding-left: 1rem;
    max-height: 93%;
}

.pcr-login-page {
    grid-area: main;
    width: 100%;
}
.exit-desc {
    height: 176px;
}

.header-desc {
    color: #212121;
    font-size: 32px;
    margin-left: 17%;
    margin-top: 30px;
    font-family: Forma DJR Micro !important;
}
.header-description {
    font-size: 15px;
    margin-left: 10%;
}
.header-extra-description {
    font-size: 15px;
    margin-left: 14%;
    font-family: Forma DJR Micro !important;
}
.header-timer-desc{
    font-size: 14px;
    margin-left: 35%;
    margin-top: 20px;
    font-family: Forma DJR Micro !important;

}
.header-timer {
    font-size: 32px;
    margin-left: 4%;
}

a {
    text-decoration: none;
}

a:hover {
    color: #01a7fa;
}

.pcr-toolbar {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0.1em;
    margin-top: 12px;
}

.pcr-toolbar-left {
    font-size: 14px;
    margin-top: -12px;
}

.pcr-toolbar-right {
    padding-bottom: 0.5em;
}

.pcr-toolbar-left-nav ul {
    padding: 0;
    display: flex;
    justify-content: center;
}

/* .pcr-toolbar-left-nav li {
  margin-right: 1.5em;
} */

.pcr-toolbar a {
    color: #767676;
    cursor: pointer;
}

.pcr-toolbar a:hover,
.pcr-toolbar a:focus {
    color: #0096d6;
    text-decoration: none;
}

.pcr-landing-content {
    height: 100%;
}

/* Config Setting page */

.pcr-configsetting_row1 {
    display: flex;
    justify-content: space-between;
}

.pcr-configsetting_panel_left {
    width: 58%;
    height: 500px;
}

.pcr-configsetting_panel_right {
    width: 38%;
}

.pcr-configsetting_panel_left_div {
    display: flex;
    justify-content: space-between;
}

.pcr-configsetting_panel_leftofleft {
    width: 55%;
}

.pcr-configsetting_panel_rightofleft {
    width: 30%;
}

.pcr-configsetting_row2 {
    margin: 20px auto;
}

.genrals_secondary {
    height: 25px;
    _height: 36px;
    overflow: hidden;
    border: 2px solid #ccc;
    cursor: pointer;
    background: url('./assets/dropdown.png') no-repeat right;
    margin-bottom: 10px;
}

.genrals_secondary select {
    background: transparent;
    width: 110%;
    font-size: 14px;
    line-height: 1;
    border: 0;
    border-radius: 0;
    height: 29px;
    -webkit-appearance: none;
    appearance: none;
    color: #0096d6;
    font-family: Forma DJR Micro !important;
    cursor: pointer;
    z-index: -666;
}

/* starting point page */

.pcr-sp-page {
    display: flex;
    align-items: flex-start;
}

.pcr-sp-main {
    width: 75%;
    padding-right: 30px;
}

.pcr-sp-aside {
    width: 25%;
    /* margin-top: 135px;  applicable for including search */
    margin-top: 88px;
}

ul.product-message-btns {
    overflow: auto;
    margin: 5px 0;
}

ul.product-message-btns li.left {
    float: left;
    margin: 0 0 0 10px;
}

ul.product-message-btns li.right {
    float: right;
    margin: 0 10px 0 0;
}

[data-tab-content] {
    display: none;
}

.active[data-tab-content] {
    display: block;
}

.nav-list {
    display: flex;
}

.ui-tabs-nav {
    padding-left: 0px;
    background: transparent;
    border-width: 0px 0px 1px 0px;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    font-size: 8pt;
}

.ui-widget {
    font-family: Forma DJR Micro !important;
    font-size: 16px !important;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
    font-family: Forma DJR Micro !important;
    font-size: 14px;
}

.ui-widget-content {
    background: #ffffff !important;
}

.ui-widget-content a {
    color: #007dba !important;
}

.nav {
    height: 80px;
    border: 1px solid lightgray;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.nav .nav-btn {
    width: 40px;
    height: 100%;
    cursor: pointer;
}

.nav .nav-btn.left {
    float: left;
    background-position: 8px -98px;
}

.nav .nav-btn.left.inactive {
    background-position: 8px 22px;
}

.nav .nav-btn.right {
    float: right;
    background-position: 10px -158px;
}

.nav .nav-btn.right.inactive {
    background-position: 10px -38px;
}

.nav.hide-nav-btns .nav-btn {
    /* 	width: 10px; */
    /* 	visibility: hidden; */
    display: none;
}

.nav ul.nav-list {
    height: 100%;
    position: absolute;
    padding: 0;
    border: none;
}

.nav .nav-list li.foo {
    float: left;
    width: 90px;
    height: 60px;
    background: none;
    margin: 0;
    padding: 0 5px;
    border: none;
    cursor: pointer;
}

.nav .nav-list li.foo a {
    display: block;
    width: 100%;
    padding: 0;
    position: relative;
    border: none;
    cursor: pointer;
    text-decoration: none;
}

.nav .nav-list img.section {
    top: 10px;
    display: block;
    margin: 0 auto;
    /* changed before margin:0 auto */
    position: relative;
    border: none;
    width: 50px;
    height: 30px;
    transition: border-bottom 0.5s ease-in-out;
    /* newly added */
}

/* newly added */
.nav .nav-list img.section:hover {
    transform: translateY(-2px);
}

/* newly added */

.nav .nav-list img.error {
    position: absolute;
    margin: 5px auto;
    /* changed before margin:0 auto */
    left: 3px;
    top: 3px;
    width: 32px;
    height: 32px;
    transition: border-bottom 0.5s ease-in-out;
}

/* newly added */
.nav .nav-list img.error:hover {
    transform: translateY(-5px);
}

/* newly added */

.nav-bar p {
    font-size: 9px;
    margin: 0;
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    position: relative;
    top: 10px;
    padding-top: 5px;
    height: 25px;
    text-decoration: none;
    background: none;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}

.nav .nav-list li.active img.section {
    width: 66px;
    height: 40px;
}

.nav .nav-list li.active p {
    color: white;
    background-color: #003366;
    top: 0;
    text-decoration: underline;
}

.nav .nav-list li.active p:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 36px;
    bottom: -9px;
    border: 9px solid #003366;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom: none;
}

div.section_tabs div.section_div {
    /* 	height: 600px; */
    overflow: auto;
    position: relative;
    padding: 0;
    border: none;
    /* Mozilla: */
    background: -moz-linear-gradient(top, #ffffff, #f0f0f9) !important;
    /* Chrome, Safari:*/
    background: -webkit-gradient(linear,
            left top,
            left bottom,
            from(#ffffff),
            to(#f0f0f9)) !important;
    /* MSIE */
    filter: progid:DXImageTransform.Microsoft.Gradient(StartColorStr='#ffffff', EndColorStr='#F0F0F9', GradientType=0) !important;
}

TABLE.tablemulti {
    border: 0px solid gray;
    padding: 5px;
    /* box-shadow: 5px 5px 5px #888888; */
    /* background-color: #e0e0ff; */
}

.tableheader {
    padding: 7px;
    font-weight: bold;
    font-size: 9pt;
    color: #ffffff;
    /* background-color: #868686; */
    text-decoration: none;
}

TD.tabledetail {
    padding-left: 0px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: normal;
    font-size: 9pt;
    color: #000000;
    /* background-color: #f0f0ff; */
    text-decoration: none;
}

.choicetitle {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    font-weight: bold;
    font-size: 9pt;
    color: #0099aa;

    /* width: 800px; */
}

.choicetitle-label {
    width: 800px;
    margin-bottom: 5px;
}

.choice_point {
    margin: 10px 10px 0px 10px;
}

select.cp-dropdown option {
    color: #777;
}

select.cp-dropdown option.warn,
select.cp-dropdown.warn {
    color: rgb(112, 173, 71);
}

select.cp-dropdown option.error,
select.cp-dropdown.error {
    color: red;
}

select.required,
select.required option.mka-selection,
a.required {
    color: #ff0000 !important;
}

span.required {
    margin-left: 5px;
    font-weight: bold;
    font-size: 9pt;
    color: #ff0000 !important;
}

span.required_selected {
    margin-left: 5px;
    font-weight: bold;
    font-size: 9pt;
    color: #007dba !important;
}

.multi-select {
    border: 1px solid #ccc;
    padding: 0px 2px;
    outline: none;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    font-family: Forma DJR Micro !important;
    font-size: 14px;
    color: #777;
    margin: 0;
    background: #fff;
    cursor: pointer;
}

.multi-select:focus,
.multi-select:hover {
    border: 2px solid #aaaaaa;
}

/* Home Screen */

.main-container {
    display: flex;
}

.main-container-section {
    max-width: 75%;
}

.main-aside {
    max-width: 20%;
}

.main-subtitle {
    font-size: 2rem;
    justify-content: center;
}

.main-container-section1,
main-container-section2 {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.models {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.models li {
    list-style-type: none;
    padding: 0;
    flex: 0 1 34rem;
    margin: 1rem;
    height: 37rem;
    border-bottom: 0.1rem #c0c0c0 solid;
}

.model {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.model-desc {
    font-size: 2rem;
    font-weight: bold;
}

.model-Kmat {
    font-size: 1.5rem;
    color: #808080;
}

.model-image {
    max-width: 34rem;
    max-height: 34rem;
}

/* Configure Screen */

.configure {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: auto;
}

.configure-main {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.configure-bom {
    width: 30%;
    display: grid;
    /* border: 1px solid grey;
 */
}

/* Main Screen */
.main-dropdown {
    margin-bottom: 1em;
}

.cstic-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
}

/* Bom Section */

.bom-sub-title {
    font-size: 14px;
    line-height: 22px;
    color: rgb(0, 150, 214);
    padding-left: 111px;
}

.bom-list-item {
    padding: 0.3em;
}

.bom-list {
    list-style-type: none;
    padding: 0;
    font-size: 0.75rem !important;
}

/*
.select {
  background: transparent;
  font-size: 14px;
  line-height: 1;
  border: 1;
  border-radius: 0;
  height: 29px;
  color: #0096d6;
  font-family: HPSimplified_Rg, sans-serif !important;
  cursor: pointer;
  border: 2px solid #ccc;
  padding-left: 4px;
  margin-bottom: 0.5em;
}

.select-single {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("./assets/dropdown.png");
  background-position: right 4px top 50%;
  background-repeat: no-repeat;
  background-size: 25px;
  padding-right: 30px;
}

*/

select.cp-dropdown option {
    color: #777;
}

.select:focus,
.select:hover {
    border: 2px solid #aaaaaa;
}

.select-checkbox option::before {
    content: '\2610';
    width: 1.3em;
    text-align: center;
    display: inline-block;
}

.select-checkbox option:checked::before {
    content: '\2611';
}

/*  global styles */

/* #Typography
================================================== */
.MuiTypography-root,
.MuiTabs-root,
.MuiMenuItem-root {
    font-family:Forma DJR Micro !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 10px 0;
    line-height: 20px;
    color: inherit;
}

h1 {
    font-size: 34px;
    line-height: 32px;
}

h2 {
    font-size: 30px;
    line-height: 30px;
}

h3 {
    font-size: 25px;
    line-height: 24px;
}

h4 {
    font-size: 20px;
    line-height: 22px;
    color: rgb(0, 150, 214);
}

h5 {
    font-size: 18px;
    line-height: 22px;
}

.subheader {
    color: #777;
}

p {
    margin: 0 0 6px 0;
}

p img {
    margin: 0;
}

p.lead {
    font-size: 21px;
    line-height: 27px;
    color: #777;
}

em,
strong {
    color: #000000;
    font-weight: bold;
}

/*  Primary Content */
.white {
    color: #fff;
}

a.white,
a.white:hover {
    color: #fff;
}

/*  Smaller font size for secondary content */
.small {
    font-size: 80%;
}

.typography_secondary {
    color: #5a5a5a;
}

.left_div {
    width: 45%;
    height: 1px;
    margin: 0 20px 20px 20px;
    padding: 0;
}

.remove-top {
    margin-top: 0 !important;
}

.padding20 {
    padding: 20px;
}

.padding10 {
    padding: 10px;
}

.padding5 {
    padding: 5px;
}

.dark-background {
    background-color: #767676;
}

.dark-border {
    border: 2px solid #767676;
}

.button input {
    border: none;
    font: inherit;
    color: inherit;
    background: transparent;
    overflow: visible;
}

.button {
    font-family: Forma DJR Micro !important;
    -moz-border-radius: 0 5px;
    border: none;
    border-radius: 0 5px;
    padding: 0 7px;
    display: inline-block;
    /* height: 40px; */
}

.button .button input {
    text-transform: none;
    line-height: 38px;
    font-size: 20px;
    font-weight: bold;
    border: none;
}

.button:hover,
.button:focus {
    background-color: #d6492d;
    text-decoration: none;
}

.button:visited span {
    color: #ffffff;
}

.button {
    background-color: #f05332;
}

a.button,
a.button:hover,
a.button:focus {
    color: #fff;
    text-decoration: none;
}

.button.inline {
    line-height: 34px;
    font-size: 16px;
    -moz-border-radius: 0 6px;
    border-radius: 0 6px;
}

.button.inline input {
    height: 36px;
    font-size: 18px;
}

.button.inline {
    height: 36px;
    padding: 0 15px;
    margin-left: 5px;
}

.button.slim .btn_label,
.slim input {
    line-height: 26px;
    font-size: 14px;
    margin-bottom: 1px;
}

input .button.slim {
    height: 30px;
}

.button.slim {
    margin: 0 5px;
    padding: 8px 20px 8px 20px;
    -moz-border-radius: 0 5px;
    border-radius: 12px;
}

input[type='button'].button.multiline {
    white-space: normal;
    width: 180px;
    height: 40px;
    margin: 0 3px;
    padding: 0 10px;
    -moz-border-radius: 0 6px;
    border-radius: 0 6px;
}

.button.critical,
.button.critical input {
    background-color: rgb(0, 150, 214);
    cursor: pointer;
    color: #fff;
}

.button.critical:hover,
.button.critical:hover input,
.button.critical:focus,
.button.critical:focus input {
    background-color: #c1401a;
    color: #fff;
}

.button.critical_disabled:hover,
.button.critical_disabled:focus,
.button.critical_disabled,
.button.critical_disabled input {
    color: #ffffff;
    background-color: #cccccc;
    cursor: default;
}

.button.cancel,
.button.cancel input {
    color: #ffffff;
    background-color: #767676;
    cursor: default;
}

.button.cancel:hover,
.button.cancel:hover input,
.button.cancel:focus,
.button.cancel:focus input {
    background-color: #5a5a5a;
}

.button.cancel_disabled:hover,
.button.cancel_disabled:focus,
.button.cancel_disabled,
.button.cancel_disabled input {
    color: #ffffff;
    background-color: #cccccc;
    cursor: default;
}

.button.primary,
.button.primary input {
    color: #ffffff;
    background-color: #0096d6;
    cursor: pointer;
    border-radius: 11px;
}

.button.primary:hover,
.button.primary:hover input,
.button.primary:focus,
.button.primary:focus input {
    background-color: #006699;
}

.button.primary_disabled:hover,
.button.primary_disabled:focus,
.button.primary_disabled,
.button.primary_disabled input {
    background-color: #ccc;
    color: #fff;
    cursor: default;
}

.button.secondary,
.button.secondary:hover,
.button.secondary:hover input,
.button.secondary input {
    color: #ffffff;
    background-color: #0096d6;
    border-radius: 11px;
    padding: 8px 12px 8px 18px;
}


.button.secondary_disabled:hover,
.button.secondary_disabled:focus,
.button.secondary_disabled,
.button.secondary_disabled input {
    background-color: #ccc;
    color: #fff;
    cursor: default;
}

/* Newly Added */
.form-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    padding: 15px 40px;
    line-height: 1.5em;
}

.form-row {
    padding: 10px 0;
    display: flex;
}

.form-row label {
    padding-right: 10px;
    flex: 1;
}

.form-row input {
    flex: 1;
}

/* .wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper > * {
  padding: 20px;
} */

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1px;
}

.navbar__link {
    margin: 0.5em;
    cursor: pointer;
    text-decoration: none;
    justify-content: space-evenly;
}

.navbar__link:hover {
    transform: translateY(0px);
}

.navbar__link--active {
    border-bottom: 3px solid #29b6f6;
    transition: border-bottom 0.5s ease-in-out;
}

.genrals_secondary_dateInput {
    overflow: hidden;
    cursor: pointer;
}

.MuiStepConnector-lineHorizontal {
    border-top-style: solid;
    border-top-width: 3px !important;
}

.MuiStepConnector-line {
    display: block;
    border-color: #29a8dd !important;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0 !important;
}

.MuiAccordionDetails-root {
    display: flex;
    padding: 0px 18px 0px !important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 6px !important;
    text-align: center !important;
    font-weight: bold !important;
    font-size: 0.75em !important;
    margin-bottom: 3px !important;
}

.choiceSelected {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    font-weight: bold;
    font-size: 9pt;
    color: green;
    /* padding-left: 350px; */
}

.MuiTypography-body2 {
    font-size: 0.75rem !important;
    line-height: 0.8rem !important;
    /* font-family: HPSimplified_Rg, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em; */
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 0px !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
    color: rgb(0, 150, 214) !important;
    font-weight: 500;
}

/* .MuiStepLabel-alternativeLabel {
  color: black !important;
} */


.IQ_container {
    display: flex;
    justify-content: center;
    text-align: center;
}

.choicetitle-label {
    line-height: 0.8rem;
}

.MuiStepper-root {
    padding: 10px !important;
}

.bom-table {
    width: 100%;
}

.group-option {
    line-height: 1.5rem;
    /* font-weight: bold; */
}

.pcr-toolbar-role {
    font-size: 12px;
    padding-right: 28px;
    /* display: none; */
}

.bom_panel_right {
    width: 100%;
}

div.st {
    /* position: -webkit-sticky; */
    position: fixed;
    /* top: 50px; */
    /* bottom: 80px; */
    /* top: 0; */
    /* background-color: yellow;
  padding: 50px; */
    /* font-size: 20px; */
}

/* .expand-all {
  font-family: HP Simplified;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #29a8dd;
} */
.searchIcon {
    position: absolute;
    left: 0px;
    top: 10px;
    width: 20px;
    height: 20px;
}

.filterSelectAndDeselect {
    display: flex;
    justify-content: space-between;
    /* width: 600px; */
}

.bs {
    background-color: green;
    color: white;
    width: 0px;
}

.MuiInputBase-root {
    font-family: Forma DJR Micro !important;
}

.MuiOutlinedInput-input {
    padding: 8.5px 19px !important;
}

.MuiInputLabel-outlined {
    transform: translate(10px, 10px) scale(1) !important;
}

.MuiInputLabel-outlined {
    z-index: 1 !important;
    transform: translate(12px, 12px) scale(1) !important;
    pointer-events: none !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(18px, -5px) scale(0.75) !important;
}

.MuiButton-root {
    text-transform: none !important;
    font-family: Forma DJR Micro !important;
}

.MuiTabs-root {
    display: flex;
    overflow: hidden;
    height: 35px;
}

.MuiTabs-flexContainer {
    display: flex;
    align-items: center;
    height: 35px;
}

.MuiTab-root {
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    font-family: Forma DJR Micro !important;
    text-transform: none;
    line-height: 35px;
    letter-spacing: 0.02px;
    min-width: 10px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #0096d6 !important;
}

.MuiTabs-indicator {
    background-color: #0096d6 !important;
}

.MuiTableCell-root {
    font-family: Forma DJR Micro !important;
    padding: 2px !important;
    line-height: 1rem !important;
    font-size: 1rem !important;
}

.MuiTableCell-head {
    line-height: 0.45rem;
}

.currencyStyle {
    font-weight: bold;
    color: rgb(0, 150, 214);
}

.select-version {
    padding-right: 0px;
}

.MuiTab-iconWrapper {
    color: #BE1313 !important;
}

.super-treeview * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.super-treeview {
    font-size: 100%;
    font-family: Forma DJR Micro !important;
}

.super-treeview>div>.super-treeview-no-children-transition {
    transition-property: all;
}

.super-treeview>div>.super-treeview-no-children-transition-enter {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.super-treeview>div>.super-treeview-no-children-transition-enter.super-treeview-no-children-transition-enter-active {
    opacity: 1;
    height: 0;
}

.super-treeview>div>.super-treeview-no-children {
    overflow: hidden;
}

.super-treeview>div>.super-treeview-no-children>.super-treeview-no-children-content {
    font-family: Forma DJR Micro !important;
    margin: 2px 0;
}

.super-treeview>div>.super-treeview-node {
    overflow: hidden;
}

.super-treeview>div>.super-treeview-node>.super-treeview-children-container .super-treeview-node .tree-label-div {
    font-family: Forma DJR Micro !important;
    font-weight: 700;
    font-size: initial;
}

.super-treeview>div>.super-treeview-node>.super-treeview-children-container .super-treeview-node .tree-label-div {
    font-family: Forma DJR Micro !important;
    font-style: normal;
    margin-left: 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #212121;
    display: flex;
    align-items: center;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.super-treeview>div>.super-treeview-node>.super-treeview-children-container {
    margin-left: 15px;
    padding-left: 20px;
}

.super-treeview>div>.super-treeview-node>.super-treeview-children-container>.super-treeview-loading {
    padding: 0 3px;
    margin: 2px 0;
}

.super-treeview>div>.super-treeview-node>.super-treeview-node-content {
    margin: 2px 0;
    cursor: pointer;
    display: flex;
}

.super-treeview>div>.super-treeview-node>.super-treeview-node-content:hover {
    background-color: rgba(0, 0, 0, 0.04)
}


h2.MuiTypography-root.MuiTypography-h6 {
    font-size: 30px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    border-radius: 12px;
    height: 40px;
}

.super-treeview-node-content:hover .textDletButton {
    opacity: 1 !important;
}

.super-treeview-node-content:hover .moreIconButton {
    opacity: 1 !important;
}

.super-treeview>div>.super-treeview-node>.super-treeview-node-content>label {
    vertical-align: top;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    margin: 0;
    font-weight: normal;
    font-size: 100%;
    cursor: pointer;
}

.allmodel {
    color: rgb(13, 17, 17) !important;
}

.sysitem {
    color: darkorange !important;
}

.newitem {
    color: darkgreen !important;
}

.label-selected {
    font-size: 1rem;
    color: darkblue !important;
    background-color: lightblue !important;
}

.newly-created-label {
    background-color: #f5c77e;
}


.count-dis {
    display: inline-flex
}

.tree-label-div {
    display: flex;
    margin-left: 10px;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.super-treeview-text {

    font-family: Forma DJR Micro !important;
    margin-right: 10px;
    font-style: normal;
    font-weight: 400;
    align-items: center;
    color: #212121;
    cursor: pointer;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.upditem {
    color: purple !important;
}

.super-treeview>div>.super-treeview-node>.super-treeview-node-content>input[type=checkbox] {
    float: left;
    margin: 4px 5px 0 0;
    line-height: normal;
}

.super-treeview>div>.super-treeview-node>.super-treeview-node-content>.super-treeview-triangle-btn {
    float: left;
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.super-treeview>div>.super-treeview-node>.super-treeview-node-content>.super-treeview-triangle-btn-none {
    cursor: none;
}

.super-treeview>div>.super-treeview-node>.super-treeview-node-content>.super-treeview-triangle-btn-right {
    width: 8px;
    height: 10px;
    margin-top: 2px;
    margin-left: 2px;
    border-top: 5px solid transparent;
    border-left: 6px solid #000;
    border-bottom: 5px solid transparent;
}

.super-treeview>div>.super-treeview-node>.super-treeview-node-content>.super-treeview-triangle-btn-down {
    width: 10px;
    height: 8px;
    margin-top: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #000;
}

.super-treeview>div>.super-treeview-node>.super-treeview-node-content>.delete-btn {
    float: right;
    cursor: pointer;
}

.super-treeview>div>.super-treeview-node-transition {
    transition-property: all;
}

.super-treeview>div>.super-treeview-node-transition-enter {
    opacity: 0;
}

.super-treeview>div>.super-treeview-node-transition-enter.super-treeview-node-transition-enter-active {
    opacity: 1;
}

.super-treeview>div>.super-treeview-node-transition-exit {
    opacity: 1;
}

.super-treeview>div>.super-treeview-node-transition-exit.super-treeview-node-transition-exit-active {
    opacity: 0;
}
.MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-formControl.MuiInputBase-multiline.css-16ln25d-MuiInputBase-root-MuiFilledInput-root {
    background-color: whitesmoke;
}

.nodeDiv {
    width: 100%;
    height: 200px;
    flex-grow: 1;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: none;
    overscroll-behavior: contain;
    cursor: pointer;
}

.nodeDiv::-webkit-scrollbar {
    width: 15px;
}

.nodeDiv::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
}

.nodeDiv::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #DBDBDB;
}

.MuiOutlinedInput-inputAdornedEnd {
    text-overflow: ellipsis;
}

.MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-formControl.MuiInputBase-multiline.css-8ynqus {
    background-color: whitesmoke;
}

input#char-desc,
input#char-id,
input#allowedCVGs,
input#preConCode,
input#selConCode-input,
input#ItemPartNumber,
input#VcItemID {
    border-radius: 12px;
}
